<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96.04 73.49"
    fill="currentColor"
  >
    <path
      class="cls-1"
      d="M71.54,43.72c-.52,1.79-1,3.61-1.58,5.38a1.82,1.82,0,0,1-1.11,1C62.19,51.6,55.52,53,48.68,54.45V41.06l-6.63,1.4c0,2.19,0,4.28,0,6.38a5.71,5.71,0,0,1-1.18,3.79c-1,1.36-1.79,2.94-2.92,4.19a7.2,7.2,0,0,1-3.24,2.27c-6.2,1.47-12.46,2.72-19.12,4.14.69-2.36,1.24-4.49,2-6.57.14-.39.93-.68,1.46-.8,5.13-1.12,10.27-2.19,15.4-3.27.34-.08.66-.19,1-.3V43.87l-16.8,3.55c.73-2.43,1.33-4.6,2.06-6.72.12-.33.84-.54,1.31-.64,4.15-.91,8.3-1.77,12.45-2.65.3-.06.59-.15,1-.27V35.75c0-7.8,0-15.6,0-23.39a2.76,2.76,0,0,1,.95-2.31l5.21-4.46.38.29V35.79l6.61-1.39V32.92c0-5.79,0-11.57,0-17.35a3.36,3.36,0,0,1,.86-2.16c1.65-1.6,3.45-3,5.19-4.54l.38.25V33l16.43-3.5v.79c-.45,1.63-.93,3.26-1.34,4.91a2.13,2.13,0,0,1-2,1.7c-4,.79-8,1.67-12.07,2.56-.36.08-1,.39-1,.61-.07,2.07,0,4.14,0,6.34l16.38-3.5Z"
    />
    <path
      class="cls-1"
      d="M48.68,67.91c.52-2.16,1-4.33,1.62-6.45.12-.4.88-.76,1.4-.87,6.61-1.45,13.23-2.84,19.84-4.25v.79c-.5,1.75-1,3.49-1.48,5.26a1.61,1.61,0,0,1-1.45,1.25c-2.61.52-5.21,1.08-7.81,1.64L48.94,67.91Z"
    />
  </svg>
</template>
