import { ref, computed } from "vue";
import {
  CURRENCIES,
  type CurrencyCode,
  EXTRA_PRECISION,
  formatCurrency as formatCurrencyUtil,
  parseCurrency as parseCurrencyUtil,
} from "@mono/constants/lib/currency";
import Decimal from "decimal.js";
import SarIcon from "../icons/SarIcon.vue";

const currencyIcons: Record<CurrencyCode, Component | null> = {
  sar: SarIcon,
  usd: null,
  aed: null,
  bhd: null,
  kwd: null,
  omr: null,
  jod: null,
};

// state outside the composable function represent shared global app state
const currencyCode = ref<CurrencyCode>("sar");
const currentCurrency = computed(() => {
  return CURRENCIES.find((c) => c.code === currencyCode.value)!;
});

// methods
const setCurrency = (code: CurrencyCode) => {
  currencyCode.value = code;
};
const clearCurrency = () => {
  currencyCode.value = "sar";
};

export const useCurrency = ({
  extraPrecision = false,
  showZero,
}: { extraPrecision?: boolean; showZero?: boolean } = {}) => {
  const precision = extraPrecision
    ? EXTRA_PRECISION
    : currentCurrency.value.precision;

  const currencyPlaceholder = computed<string>(() => {
    return `0.${"0".repeat(precision)}`;
  });

  const currencyRegex = computed(() => {
    const zeroLeadingRegex = `0(\\.[0-9]{0,${precision}})?`;
    const nonZeroLeadingRegex = `[1-9][0-9]*(\\.[0-9]{0,${precision}})?`;
    return new RegExp(`^(${zeroLeadingRegex}|${nonZeroLeadingRegex})$`);
  });

  const formatCurrency = (value: number) => {
    try {
      if (value === 0 && !showZero) {
        // if value is 0 and showZero is false, return empty string
        // to prevent displaying zero's in the UI
        return "";
      }

      if (value === 0 && showZero) {
        // if value is 0 and showZero is true, return the currency placeholder
        // to display zero's in the UI as placeholders
        return new Decimal(0).toFixed(precision);
      }

      // remove trailing zeros
      return formatCurrencyUtil({
        value,
        currencyCode: currencyCode.value,
        extraPrecision,
      });
    } catch {
      if (showZero) {
        return new Decimal(0).toFixed(precision);
      }

      return "";
    }
  };

  const renderCurrencyWithCode = ({
    value,
    hideCurrency,
  }: {
    value: number;
    hideCurrency?: boolean;
  }) => {
    const { localizeText } = useLocalizeText();

    return h(
      "span",
      {
        style: {
          display: "inline-flex",
          alignItems: "center",
        },
      },
      [
        h(
          "span",
          { dir: "ltr", style: { "break-inside": "avoid" } },
          formatCurrency(value)
        ),
        hideCurrency
          ? null
          : currencyIcons[currencyCode.value] == null
            ? h(
                "span",
                { style: { "break-inside": "avoid" } },
                localizeText(currentCurrency.value, "suffix")
              )
            : h(currencyIcons[currencyCode.value] as Component, {
                style: {
                  width: "1.5rem",
                  height: "1.5rem",
                },
              }),
      ]
    );
  };

  const parseCurrency = (value: string | number | Decimal) => {
    try {
      return parseCurrencyUtil({
        value: new Decimal(value).toNumber(),
        currencyCode: currencyCode.value,
        extraPrecision,
      }).toNumber();
    } catch {
      return 0;
    }
  };

  return {
    Decimal,
    currentCurrency,
    currencyPlaceholder,
    setCurrency,
    clearCurrency,
    currencyRegex,
    formatCurrency,
    parseCurrency,
    renderCurrencyWithCode,
  };
};
