import { useI18n } from "vue-i18n";
import { toRaw } from "vue";
import type { Locale } from "@mono/constants/lib/locale";

export const useLocalizeText = () => {
  const { locale } = useI18n({
    useScope: "parent",
  });

  const localizeText = <T, K extends keyof T, L extends keyof T[K]>(
    obj: T | null | undefined,
    key: Exclude<K, `${string}Translations`>,
    defaultValue = "",
    customLocale?: Locale
  ) => {
    const objRaw = toRaw(obj);

    if (
      !key ||
      objRaw === null ||
      objRaw === undefined ||
      typeof objRaw !== "object" ||
      Object.keys(objRaw).length === 0
    ) {
      return defaultValue;
    }

    const localizationKey = `${String(key)}Translations` as K;
    const localizedString = objRaw[localizationKey]
      ? objRaw[localizationKey][(customLocale as L) ?? (locale.value as L)] ||
        objRaw[key]
      : objRaw[key];
    return localizedString ? (localizedString as string) : defaultValue;
  };

  return {
    localizeText,
  };
};
