import * as Sentry from "@sentry/vue";
export default defineNuxtPlugin((nuxtApp) => {
  // !custom error handler to block issue in product select
  // !this is a workaround to avoid propagating the error to nuxt error page
  nuxtApp.vueApp.config.errorHandler = (error) => {
    if (
      error instanceof Error &&
      error.message ===
        "Cannot read properties of undefined (reading 'querySelectorAll')"
    ) {
      console.error("vue error handler:", error);
      return;
    }
    throw error;
  };

  const router = useRouter();
  const {
    public: { sentry, API_TARGET },
  } = useRuntimeConfig();

  if (!sentry.dsn || sentry.disabled) {
    return;
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: API_TARGET === "production" ? 0.1 : 1.0, // This sets the sample rate at 10% in prod for normal sessions.
    replaysOnErrorSampleRate: 1.0, // force full sampling for error sessions.
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', 'https://your-server.com'],
  });
});
