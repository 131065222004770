import Decimal from "decimal.js";

export const CURRENCIES = [
  {
    code: "sar",
    precision: 2,
    displayName: "Saudi Riyal",
    displayNameTranslations: { ar: "ريال سعودي" },
    suffix: "SAR",
    suffixTranslations: { ar: "ر.س" },
    countryId: "",
  },
  {
    code: "usd",
    precision: 2,
    displayName: "US Dollar",
    displayNameTranslations: { ar: "دولار أمريكي" },
    suffix: "USD",
    suffixTranslations: { ar: "د أ" },
  },
  {
    code: "aed",
    precision: 2,
    displayName: "Emirati Dirham",
    displayNameTranslations: { ar: "درهم إماراتي" },
    suffix: "AED",
    suffixTranslations: { ar: "د إ" },
  },
  {
    code: "bhd",
    precision: 3,
    displayName: "Bahraini Dinar",
    displayNameTranslations: { ar: "دينار بحريني" },
    suffix: "BHD",
    suffixTranslations: { ar: "د ب" },
  },
  {
    code: "kwd",
    precision: 3,
    displayName: "Kuwaiti Dinar",
    displayNameTranslations: { ar: "دينار كويتي" },
    suffix: "KWD",
    suffixTranslations: { ar: "د ك" },
  },
  {
    code: "omr",
    precision: 3,
    displayName: "Omani Riyal",
    displayNameTranslations: { ar: "ريال عماني" },
    suffix: "OMR",
    suffixTranslations: { ar: "ر ع" },
  },
  {
    code: "jod",
    precision: 3,
    displayName: "Jordanian Dinar",
    displayNameTranslations: { ar: "دينار أردني" },
    suffix: "JOD",
    suffixTranslations: { ar: "د أ" },
  },
] as const;

export const CURRENCIES_MAP = CURRENCIES.reduce(
  (acc, currency) => {
    acc[currency.code] = currency;
    return acc;
  },
  {} as Record<Currency["code"], Currency>
);

export type Currency = Readonly<(typeof CURRENCIES)[number]>;
export type CurrencyCode = Currency["code"];

export const EXTRA_PRECISION = 12;

export const parseCurrency = ({
  value,
  currencyCode,
  extraPrecision,
}: {
  value: number | Decimal;
  currencyCode: CurrencyCode;
  extraPrecision?: boolean;
}) => {
  const currency = CURRENCIES_MAP[currencyCode];

  const precision = extraPrecision ? EXTRA_PRECISION : currency.precision;

  return new Decimal(value).toDecimalPlaces(precision, Decimal.ROUND_HALF_UP);
};

export const formatCurrency = ({
  value,
  currencyCode,
  extraPrecision,
}: {
  value: number | Decimal;
  currencyCode: CurrencyCode;
  extraPrecision?: boolean;
}) => {
  const currency = CURRENCIES_MAP[currencyCode];
  const fullPrecision = extraPrecision ? EXTRA_PRECISION : currency.precision;

  const extraPrecisionNumber = extraPrecision
    ? EXTRA_PRECISION - currency.precision
    : 0;
  const formattedValue = new Decimal(value).toFixed(fullPrecision);
  if (
    extraPrecision &&
    formattedValue.endsWith("0".repeat(extraPrecisionNumber))
  ) {
    // remove trailing extraPrecision zeros
    return formattedValue.slice(0, -extraPrecisionNumber);
  } else {
    return formattedValue;
  }
};
